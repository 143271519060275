<template>
  <div class="page users messaging">
    <b-container>
      <b-row>
        <!-- <b-col cols="9">
          <div class="panel">
            <k-users-table v-model="selectedUsers"></k-users-table>
          </div>
        </b-col> -->
        <b-col cols="6" offset="3">
          <div class="panel">
            <k-send-message
              :selectedUsers.sync="selectedUsers"
            ></k-send-message>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- <k-button
      variant="primary"
      @click="UjFelhasznalo"
      size="sm"
      icon="fa-plus"
      class="add-user"
    ></k-button> -->
  </div>
</template>

<script>
import { ModalFunctions } from '../functions/ModalFunctions';
export default {
  name: 'messaging-page',
  data() {
    return {
      selectedUsers: [],
    };
  },
  mounted() {},
  created() {},
  // methods: {
  //   UjFelhasznalo() {
  //     ModalFunctions.OpenModal('k-user-kezelo-modal', null);
  //   },
  // },
  computed: {},
  watch: {},
};
</script>
