<template>
  <div class="page users">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="panel">
            <k-users-table v-model="selectedUsers"></k-users-table>
          </div>
        </b-col>
        <!-- <b-col cols="8" offset="2">
          <div class="panel">
            <k-send-message
              :selectedUsers.sync="selectedUsers"
            ></k-send-message>
          </div>
        </b-col> -->
      </b-row>
    </b-container>
    <k-button
      variant="primary"
      @click="UjFelhasznalo"
      v-if="(isProjektAdmin || isGod)"
      size="sm"
      icon="fa-plus"
      class="add-user"
    ></k-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppFunctions } from '../functions/AppFunctions';
import { ModalFunctions } from '../functions/ModalFunctions';
import { AppStoreTypes } from '../store/modules/app';
export default {
  name: 'users-page',
  data() {
    return {
      selectedUsers: [],
    };
  },
  mounted() {},
  created() {},
  methods: {
    UjFelhasznalo() {
      ModalFunctions.OpenModal('k-user-kezelo-modal', null);
    },
  },
  computed: {
    ...mapGetters({ user: AppStoreTypes.user.get }),
    isProjektAdmin() {
      return AppFunctions.IsProjektAdmin(this.user);
    },
    isGod() {
      return AppFunctions.IsGod(this.user);
    },
  },
  watch: {},
};
</script>
